import { SimpleGrid } from '@mantine/core'
import { Metrics, sum } from '@ospace/shared'
import { upperFirst } from 'lodash'

import { MetricSummary } from '../data-and-intelligence/MetricSummary'

export const CampaignOverview = (props: { campaign: any; metrics: any }) => {
  const { campaign, metrics } = props
  const lastUpdate = campaign?.objective?.results[campaign.objective.results.length - 1]

  const metricsMapped: any = {
    intents: metrics.no_of_reports,
    companiesDiscovered: metrics.discovery.companies,
    contactsDiscovered: metrics.discovery.contacts,
    meetingBooked:
      Number(metrics.teleSalesOutreach['Upcoming Meetings']) +
      Number(metrics.teleSalesOutreach['Meetings Completed']),
    targetPipelineValue: metrics.currentARR.value || 0,
    digitalLeads:
      Number(metrics.total_emails_replied || 0) + Number(metrics.total_linkedin_replied || 0),
    connectionRequestsSent: metrics.total_linkedin_requests_sent,
    connectionRequestsAccepted: metrics.total_linkedin_requests_accepted,
  }

  const renderMetricInfo = (title: string, metrics: (keyof typeof Metrics)[]) => ({
    title,
    metrics: metrics
      // .filter((key) => campaign.objective[key])
      .map((key) => ({
        title: Metrics[key],
        link: `/campaign/data-intel/${campaign.id}/dashboard/${key}`,
        target: campaign.objective[key],
        enabled: campaign.objective[`is${upperFirst(key)}Enabled`],
        value: metricsMapped[key] || sum(campaign.objective.results, key),
      })),
  })

  return (
    <>
      <SimpleGrid
        cols={2}
        breakpoints={[
          { maxWidth: '200rem', cols: 2, spacing: 'xl', verticalSpacing: 'xl' },
          { maxWidth: '80rem', cols: 1, spacing: 'xl', verticalSpacing: 'xl' },
        ]}
        p={'xl'}
      >
        {!!campaign.objective?.isDataIntelligenceActive && (
          <MetricSummary
            metricInfo={renderMetricInfo('Data and Intelligence', [
              'intents',
              'companiesDiscovered',
              'contactsDiscovered',
            ])}
          />
        )}

        {!!campaign.objective?.isTeleProspectingActive && (
          <MetricSummary
            metricInfo={renderMetricInfo('MCO', [
              'meetingBooked',
              'targetPipelineValue',
            ])}
          />
        )}

        {!!campaign.objective?.isDigitalProspectingActive && (
          <MetricSummary
            metricInfo={renderMetricInfo('Digital Prospecting', [
              'digitalLeads',
              'digitalProspectingMeetingBooked',
              'connectionRequestsSent',
              'connectionRequestsAccepted',
              'impressions',
              'campaignClicks',
            ])}
            lastUpdate={lastUpdate}
          />
        )}
      </SimpleGrid>
    </>
  )
}
